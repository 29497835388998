<template>
  <static-fullscreen-card @sendHeight="setContentHeight">
    <template v-slot:title>{{ data.city }}. {{ data.address_fact && data.address_fact.address }} ({{ data.id }})</template>
    <template v-slot:actions>
      <a-btn-edit v-if="getAccess(`${m.accessName}.edit`)" title="Редактировать" @click="showEditDialog = true" />
    </template>
    <portal to="v-main">
      <s-edit-form v-model="showEditDialog" v-if="showEditDialog" :api="url" :m="m" :config="configHead" :title="configHead.title" :id="id" />
    </portal>
    <a-loader v-if="!loaded" />
    <v-row v-else>
      <v-col cols="12" sm="6">
        <material-card>
          <template v-slot:heading> </template>
          <a-form-view :value="data" :model="modelLeft" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6">
        <material-card>
          <template v-slot:heading> </template>
          <a-form-view :value="data" :model="modelRight" />
        </material-card>
      </v-col>
      <v-col cols="12">
        <material-card color="third" class="block_detail">
          <template v-slot:heading>
            <v-tabs v-model="tab" background-color="transparent" slider-color="white" height="44">
              <v-tab v-for="(tab, i) in tabs" v-if="tab.show" :key="i" class="mr-3" :href="`#tab-${tab.name}`">
                {{ tab.title }}
              </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px" touchless>
            <v-tab-item v-for="(t, i) in tabs" :key="i" v-if="t.show" :id="`tab-${t.name}`">
              <TabShopLink v-if="t.name == 'shopLink'" :id="data.id" :height="detailHeight" />
              <TabSupply v-if="t.name == 'supply'" :id="data.id" :height="detailHeight" />
              <TabDocs v-if="t.name == 'doc'" :data="data" :height="detailHeight" />
            </v-tab-item> </v-tabs-items
        ></material-card>
      </v-col>
    </v-row>
  </static-fullscreen-card>
</template>

<script>
import { autoHeightBlock, getAccess, popupMenu, removeEl, genModel } from "@/components/mixings";
export default {
  mixins: [autoHeightBlock, getAccess, popupMenu, removeEl, genModel],
  props: {
    idShow: Number,
  },
  components: {
    TabSupply: () => import("./tabs/tabSupply"),
    TabShopLink: () => import("./tabs/tabShopLink"),
    TabDocs: () => import("./tabs/tabDocs"),
  },
  data() {
    return {
      loaded: false,
      id: 0,
      showEditDialog: false,
      suEditShow: false,
      showSalesDialog: false,
      title: "",
      m: this.$store.getters["config/get"].models.vendor,
      data: {},
      tab: 0,
      tab_photo: 0,
      tabs: [
        { name: "supply", title: "Поставки", show: !true },
        { name: "doc", title: "Документы", show: !true },
        { name: "shopLink", title: "Наши магазины", show: true },
        { name: "report", title: "Отчеты", show: this.getAccess("shop.accounting") || !true },
      ],
    };
  },

  created() {
    this.$root.title = "Поставщик";
    this.id = this.idShow || Number(this.$route.params.id);
    this.fitchData();
  },
  computed: {
    configHead() {
      let type = this.$root.profile.role;
      let config = JSON.parse(JSON.stringify(this.m?.config?.[type] || this.m?.config.default || {}));
      return config || {};
    },
    url() {
      let res;
      if (typeof this.m.api == "object") {
        res = this.m.api?.[this.$root.profile.role] || this.m.api.default;
      } else res = this.m.api;
      return res;
    },
    modelLeft() {
      let model = this.calcModel("viewForm1");
      return model;
    },
    modelRight() {
      let model = this.calcModel("viewForm2");
      return model;
    },
    model: {
      get() {
        let model = this.calcModelConfig(this.configHead);
        return model;
      },
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
      }
    },
  },
  methods: {
    async fitchData() {
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
        this.loaded = true;
      }
    },
  },
};
</script>
